<template>
  <div>
    <div v-if="error" class="err" :class="{ active: error }">
      {{ $t('alerts.login.errorRecovery') }}
    </div>
    <div v-if="message" class="info" :class="{ active: true }">
      {{ message }}
    </div>
    <p>
      {{ $t('loginPage.pages.recovery.disclaimer') }}
    </p>
    <v-text-field
      outlined
      id="input-email"
      :label="$tt.capitalize($t('labels.email'))"
      placeholder=""
      v-model="auth.email"
      @keydown.enter="revocery"
    ></v-text-field>
    <v-btn
      id="btn-recovery"
      large
      depressed
      block
      color="primary"
      @click="recovery"
      :disabled="sending"
      :loading="sending"
      >{{ $t('labels.send') }}</v-btn
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    auth: {
      email: '',
    },
    error: false,
    message: false,
  }),
  methods: {
    async recovery() {
      let recoveryGtmData = {};
      this.error = false;
      this.message = false;

      const result = await this.$store.dispatch(
        'user/generate_reset_token',
        this.auth.email
      );

      this.$gtm.trackEvent({
        category: 'recovery',
        event: 'click',
        action: 'recovery-click',
        label: 'btn-recovery',
      });

      if (result) {
        recoveryGtmData = {
          category: 'recovery',
          event: 'response',
          action: 'recovery-response',
          label: 'recovery-response-success',
          value: 1,
        };

        this;
        this.$router.push({
          name: 'verify-code',
        });
      } else {
        recoveryGtmData = {
          category: 'recovery',
          event: 'response',
          action: 'recovery-response',
          label: 'recovery-response-fail',
          value: 0,
        };
        this.error = true;
      }

      this.$gtm.trackEvent(recoveryGtmData);
    },
  },
  computed: {
    sending() {
      const sending = this.$store.getters['auth/getSending'];
      return sending;
    },
  },
};
</script>
