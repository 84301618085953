<template>
  <f-wrapper page="recovery">
    <f-login-container>
      <h1>{{ $tt.capitalize($t('loginPage.pages.recovery.title')) }}</h1>
    </f-login-container>

    <f-card>
      <f-recovery-form />
    </f-card>
  </f-wrapper>
</template>

<script>
import Wrapper from '../../components/views/login/Wrapper.vue';
import FCard from '../../components/shared/Card.vue';
import FLoginContainer from '../../components/views/login/LoginContainer.vue';
import FRecoveryForm from '../../components/views/login/RecoveryForm.vue';

export default {
  components: {
    'f-wrapper': Wrapper,
    FLoginContainer,
    FCard,
    FRecoveryForm,
  },
  mounted() {
    let loggedUser = localStorage.getItem('fluke-lms-user');
    if (loggedUser) {
      this.$router.push({
        name: 'course-list',
      });
    }
  },
};
</script>
